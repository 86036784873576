export default class Header {

	constructor(el) {

		this.DOM = { el: el };
		this.DOM.body = document.body;
		this.DOM.brand = this.DOM.el.querySelector('.brand');
		this.DOM.header = this.DOM.el.querySelector('.Header');
		this.DOM.toggle = this.DOM.el.querySelector('.Toggle__menu');
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container');
		this.menuOpen = false;

		this.menuOpen = false;
		this.menuAnimating = false;

		this.init();

	}

	init() {


		// Toggle
		this.DOM.toggle.addEventListener('click', (e) => {

			e.preventDefault();
			if (this.menuAnimating) return;
			this.menuOpen == true ? this.close() : this.open();			

		});

	}

	open() {

		return new Promise((resolve, reject) => {

			// Console.log('Open Menu');
			this.menuOpen = true;
			this.menuAnimating = true;
			this.DOM.body.classList.add('showMenu');
			this.DOM.body.style.overflow = 'hidden';


       const openMenu = new TimelineMax({paused:true, onComplete:() => {
         this.menuAnimating = false;
       }})
       
       openMenu.fromTo(document.querySelector(".shape-nav-1"), 1.7, {rotation:-10,y:"50%"}, {rotation:0,y:"0%", ease:Power3.easeInOut}, 0);
       openMenu.fromTo(document.querySelector(".shape-nav-2"), 1.7, {rotation:40, y:"200%"}, {rotation:0, y:"0%", ease:Power3.easeInOut}, 0);
       openMenu.fromTo(document.querySelector(".shape-nav-3"), 1.7, {rotation:20, y:"100%"}, {rotation:0, y:"0%", ease:Power3.easeInOut}, 0);
       openMenu.fromTo(document.querySelector(".Nav__main"), .6, {y:"50%"}, {y:"0%", ease:Power3.easeInOut}, 0);
       openMenu.fromTo(document.querySelector(".Nav__subnav"), .6, {y:"50%"}, {y:"0%", ease:Power3.easeInOut}, 0);
       openMenu.fromTo(document.querySelector(".contact"), .6, {y:"50%"}, {y:"0%", ease:Power3.easeInOut}, 0);
       openMenu.fromTo(this.DOM.menuContainer, 1, {y:"100%"}, {y:"0%", ease:Power3.easeInOut}, 0);
       openMenu.to(this.DOM.toggle, 1, {className:"+=is--open"}, 0.6);
       openMenu.to(this.DOM.brand, 1, {className:"+=is--white"}, 0.4);

       openMenu.to(document.querySelector("header"), 1, {className:"+=menu-color"}, 0.4);
       openMenu.play();


		});

	}

	close() {
		return new Promise((resolve, reject) => {

			// Console.log('Close Menu');
			this.menuOpen = false;
			this.menuAnimating = true;
			this.DOM.body.style.overflow = '';

       const closeMenu = new TimelineMax({paused:true, onComplete:() => {
         this.menuAnimating = false;
	 			this.DOM.body.classList.remove('showMenu');
       }})
       
       closeMenu.fromTo(document.querySelector(".shape-nav-1"), .7, {rotation:0,y:"0%"}, {rotation:10,y:"-50%", ease:Power3.easeInOut, clearProps:"all"}, 0);
       closeMenu.fromTo(document.querySelector(".shape-nav-2"), .7, {rotation:0, y:"0%"}, {rotation:-40, y:"-200%", ease:Power3.easeInOut, clearProps:"all"}, 0);
       closeMenu.fromTo(document.querySelector(".shape-nav-3"), .7, {rotation:0, y:"0%"}, {rotation:20, y:"-100%", ease:Power3.easeInOut, clearProps:"all"}, 0);
       closeMenu.fromTo(document.querySelector(".Nav__main"), .3, {y:"0"}, {y:"-50%", ease:Power3.easeInOut, clearProps:"all"}, 0);
       closeMenu.fromTo(document.querySelector(".Nav__subnav"), .3, {y:"0%"}, {y:"-50%", ease:Power3.easeInOut, clearProps:"all"}, 0);
       closeMenu.fromTo(document.querySelector(".contact"), .3, {y:"0%"}, {y:"-50%", ease:Power3.easeInOut, clearProps:"all"}, 0);
       closeMenu.to(this.DOM.menuContainer, 0.8, {y:"-100%", ease:Power3.easeInOut, clearProps: "all"}, 0.1);




       // closeMenu.call(()=> {
       //   this.Forms.render(false);
       //   this.Forms.leaveForms();
       // }, null, this, 0);
       closeMenu.to(this.DOM.toggle, 1, {className:"-=is--open"}, 0.45);
       closeMenu.to(this.DOM.brand, 1, {className:"-=is--white"}, 0.25);
       closeMenu.to(document.querySelector("header"), 1, {className:"-=menu-color"}, 0.4);


       closeMenu.play();

		});

	}

}
