import Highway from '@dogstudio/highway';

export default class SingleEtude extends Highway.Renderer {

  onEnter() {

    this.DOM = { view: this.wrap.lastElementChild };

    
    $(".tab_impact").on("click",function(e){
      e.preventDefault();
      
      $(this).parent("li").toggleClass("active");

      $("#presentation_header").hide();
      $("#presentation_content").hide();

      $("#impacts_header").show();
      $("#impacts_content").show();
    });

    $(".tab_presentation").on("click",function(e){
      
      e.preventDefault();
      $(this).parent("li").toggleClass("active");
      
      $("#impacts_header").hide();
      $("#impacts_content").hide();

      $("#presentation_header").show();
      $("#presentation_content").show();
    });

  }
}
