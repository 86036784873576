import Highway from '@dogstudio/highway';

export default class CentreDeRessourcesRenderer extends Highway.Renderer {

	onEnter() {

		this.DOM = { view: this.wrap.lastElementChild };

		$('.open-popup-link').magnificPopup({
		  type:'inline',
		  closeBtnInside:true,
		  midClick: true,
		  removalDelay: 500
		});

		$('#search').on('keyup keypress', function(e) {
		  var keyCode = e.keyCode || e.which;
		  if (keyCode === 13) {
		    e.preventDefault();
		    return false;
		  }
		});

		function initial_setting(){
		  $(".ressource-card").hide();
		  $('.ressources-section').fadeIn();
			$('.ressources-section .ressource-card').filter(":nth-child(1), :nth-child(2) , :nth-child(3) , :nth-child(4) , :nth-child(5), :nth-child(6) , :nth-child(7) , :nth-child(8) ,:nth-child(9), :nth-child(10) , :nth-child(11) , :nth-child(12)").fadeIn();
		  $('.more_ressource').fadeIn();
		  remove_more_btn();
		}

		// remove more btn
		function remove_more_btn(){
		  $.each($(".ressources-section"), function(){
		    var elements = get_nb_elements($(this));
		    if (elements[1].length == (elements[0].length)) {
		      $(this).next().fadeOut();
		    }
		  });
		}
		remove_more_btn();

		function filter_process(){
		  $('.more_ressource').fadeOut();
			$(".no-result").fadeOut();

		  $('.ressource-card').hide();
		  for (var i = 0; i < theme_selected.length; i++) {
		    if (search.length == 0) {
		      $('.' + theme_selected[i]).fadeIn();
		    }else {
		      $('.' + theme_selected[i] + "[data-search*='" + search + "']").fadeIn();
		    }
		  }
		  if (theme_selected.length == 0 && search.length != 0) {
		    $(".ressource-card[data-search*='" + search + "']").fadeIn();
		  }

		  if (theme_selected.length == 0 && search.length == 0){
		    initial_setting();
		  }

			var visible_elements = [];
			$.each($(".ressource-card:visible"), function(){
				visible_elements.push($(this).val());
			});
			if (visible_elements.length == 0){
				$(".no-result").fadeIn();
		  }


		  clean_section();
		}

		function get_nb_elements(section_sibling){
		  var visible_elements = [];
		  $.each(section_sibling.find(".ressource-card:visible"), function(){
		    visible_elements.push($(this).val());
		  });
		  var total_elements = [];
		  $.each(section_sibling.find(".ressource-card"), function(){
		    total_elements.push($(this).val());
		  });

		  return [visible_elements,total_elements];
		}

		function clean_section(){
		  $(".ressources-section").show();
		  var visible_elements = [];
		  $.each($(".ressources-section"), function(){
		    visible_elements = $(this).find(".ressource-card:visible").data("search");
		    if (visible_elements == undefined) {
		      $(this).fadeOut();
		    }
		  });
		}

		// Theme filter
		var theme_selected = [];
		$(".city_selector").on("click",function(){
		  var value = $(this).val();
		  theme_selected = [];
		  $.each($("input[name='theme']:checked"), function(){
		      theme_selected.push($(this).val());
		  });
		  filter_process();
		});

		// Search
		var timerSearch = null;
		$("#search").on("keyup", ()=> {
			clearTimeout(timerSearch);
			timerSearch = setTimeout(()=>{
				$("#search-articles"). trigger("submit");
			}, 200);
		})

		var search = "";
		$("#search-articles").on("submit",function(e){
		  e.preventDefault();
		  search = $("#search").val().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(" ","-").toLowerCase();
			console.log(search);
		  filter_process();
		});

		$(".more_ressource").on("click",function(e){
		  var nb_more_ressource = 4;
		  e.preventDefault();
		  var self = $(this);

		  var section_sibling = self.prev();
		  var elements = get_nb_elements(section_sibling);
		  console.log(elements);

		  for (var i = elements[0].length; i < (elements[0].length + nb_more_ressource +1) ; i++) {
		    section_sibling.find("div.ressource-card:nth-child(" + i + ")").fadeIn();
		  }

		  var elements = get_nb_elements(section_sibling);

		  if (elements[1].length == (elements[0].length)) {
		    self.fadeOut();
		  }
		})

		// Mes ressources
		$("#my_ressrouces").on("click",function(){
		  $(this).toggleClass("active");
		  $("#all_ressrouces").toggleClass("active");
		  $('.more_ressource').fadeOut();
		  $('.ressource-card').hide();
		  $('.my_ressource').fadeIn();
		  clean_section();
		})

		$("#all_ressrouces").on("click",function(){
		  $(this).toggleClass("active");
		  $("#my_ressrouces").toggleClass("active");
		  initial_setting();
		})

		//FORM LOGIN
		$("#login-form").on("submit", function(e) {
	    e.preventDefault();
	    var self = $(this);
	    var ajaxurl = self.data("ajaxurl");
			console.log(self.serialize());

	    $.ajax({
	      type: "POST",
	      url: ajaxurl,
	      data: self.serialize(),
	      success: function(data) {
	        var data = $.parseJSON(data);
	        console.log(data);
					$("#login-form .form-result").show().html(data.message);
	        if (data.status == true) {
						$(location).attr('href', data.redirect);
	        }
	      },
	      error: function(data) {
	        var data = $.parseJSON(data);
	        console.log(data);
					$("#login-form .form-result").show().html(data.message);
	      }
	    });
		})

	}
}
