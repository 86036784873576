export default class pageAnchors {

  constructor(container) {

    this.DOM = {
      container: container
    };

    this.DOM.AnchorsLinks = this.DOM.container.querySelectorAll('.anchors__item');
    if (this.DOM.AnchorsLinks.length === 0) return;

    this.init();

  }

  init() {


    //if (window.DEVMODE) console.log('Init Anchors');

    this.DOM.AnchorsLinks.forEach((item) => {

      item.addEventListener('click', (e) => {

        e.preventDefault();
        // anchors active
        if (this.DOM.container.querySelector('.is-active')) this.DOM.container.querySelector('.is-active').classList.remove('is-active');

        const targetAnchor = e.currentTarget.getAttribute('href');
        e.currentTarget.classList.add('is-active');
        //TweenMax.to(window, 1.2, { scrollTo: { y: document.querySelector(targetAnchor).offsetTop, autoKill: false }, ease: Power3.easeInOut });

       const smoothScrolz = new TimelineMax({paused:true, onComplete:() => {
        
       }})

      smoothScrolz.to(window, 1.2, { scrollTo:"#about", ease: Power3.easeInOut });
      //smoothScrolz.fromTo(document.querySelector(".contact"), .3, {y:"0%"}, {y:"-50%", ease:Power3.easeInOut}, 0);

      smoothScrolz.play();


      });
    });



  }

}
