import Highway from '@dogstudio/highway';

export default class ActualitesRenderer extends Highway.Renderer {

	onEnter() {

		this.DOM = { view: this.wrap.lastElementChild };

		var theme_selected = [];
		$(".city_selector").on("click",function(){
		  var value = $(this).val();
		  theme_selected = [];
		  $.each($("input[name='theme']:checked"), function(){
		      theme_selected.push($(this).val());
		  });
		  filter_process();
		});

		function get_nb_elements(){
		  var visible_elements = [];
		  $.each($("#news-list").find(".news-card:visible"), function(){
		    visible_elements.push($(this).val());
		  });
		  var total_elements = [];
		  $.each($("#news-list").find(".news-card"), function(){
		    total_elements.push($(this).val());
		  });

		  return [visible_elements,total_elements];
		}

		function filter_process(){
		  $('.news-card').hide();
		  $('.load-more').fadeOut();
		  for (var i = 0; i < theme_selected.length; i++) {
		    $('.' + theme_selected[i]).fadeIn();
		  }
		  if (theme_selected.length == 0){
		    initial_setting();
		  }
		}

		function initial_setting(){
		  $('.load-more').fadeIn();
		  for (var i = 1; i < (news_visible + 1); i++) {
		    $('.news-card:nth-child('+ i +')').fadeIn();
		  }
		  var elements = get_nb_elements();
		  if (elements[1].length == (elements[0].length)) {
		    $(".load-more").fadeOut();
		  }
		}

		var news_visible = 6;
		$(".load-more").on("click",function(e){
		  e.preventDefault();
		  var self = $(this);
		  var nb_more_news = 3;

		  news_visible += nb_more_news;

		  var elements = get_nb_elements();

		  for (var i = elements[0].length; i < (elements[0].length + nb_more_news +1); i++) {
		    $("div.news-card:nth-child(" + i + ")").data("visible","true").fadeIn();
		  }

		  var elements = get_nb_elements();
		  if (elements[1].length == (elements[0].length)) {
		    self.fadeOut();
		  }
		})

		initial_setting();


	}
}
