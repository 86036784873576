export default class Intro {

  constructor(el, page) {

    this.DOM = { el: el };
    this.DOM.Body = document.body;
    this.DOM.App = document.body.querySelector('.App');
    this.DOM.view = page;
    this.DOM.canvas = this.DOM.el.querySelector('#introCanvas');
    this.ctx = this.DOM.canvas.getContext('2d');

    this.introStep2 = false;
    this.introEnded = false;
    this.formWidth = 200;

    // Waiting Video Loaded
    this.DOM.introVisual = this.DOM.el.querySelector('#introVideo');
    
    if (this.DOM.introVisual) {
      this.DOM.introVisual.type = 'video';
      this.DOM.introVisual.load();
      this.DOM.introVisual.addEventListener('loadeddata', (e) => {
        this.init(this.DOM.introVisual.type);
      });
      
    } else {
      this.DOM.introVisual = this.DOM.el.querySelector('#introImage');
      this.DOM.introVisual.type = 'image';
      this.init(this.DOM.introVisual.type);
    }

  }

  init(type) {

    console.log('init Intro');
    
    if (type == 'video') {
      // Play Video Intro
      this.DOM.introVisual.play();
    }

    // Resize Event
    this.resizeCanvas();
    
    // Create Text
    this.createText();

    // Create Forms
    this.createRects(this.formWidth);

    // Animation
    this.animate();

    // Render
    TweenMax.ticker.addEventListener('tick', this.render, this, true, 1);

  }
  
  //**** Text

  createText() {
    
    this.DOM.text = this.DOM.el.querySelector('#introTxt');
    this.DOM.text.split = new SplitText(this.DOM.text, {type:"chars"}), 
    this.DOM.text.chars = this.DOM.text.split.chars;
    
  }
  
  //**** Video

  drawVideo() {
    this.ctx.translate(this.DOM.canvas.width * 0.5, this.DOM.canvas.height * 0.5);
    this.ctx.drawImage(this.DOM.introVisual, -this.DOM.introVisual.w * 0.5, -this.DOM.introVisual.h * 0.5, this.DOM.introVisual.w, this.DOM.introVisual.h);
    this.ctx.restore();
  }

  resizeVisual(visual) {

    let visualAspectRatio;
    if (visual.type == 'video') visualAspectRatio = visual.videoWidth / visual.videoHeight
    else visualAspectRatio = visual.clientWidth / visual.clientHeight
    
    const canvasAspectRatio = this.DOM.canvas.width / this.DOM.canvas.height;

    // Check Ratio
    if (visualAspectRatio < canvasAspectRatio) {
      
      visual.w = this.DOM.canvas.width;
      if (visual.type == 'video') visual.h = visual.videoHeight * (visual.w / visual.videoWidth);
      if (visual.type == 'image') visual.h = visual.clientHeight * (visual.w / visual.clientWidth);
      
      
    } else if (visualAspectRatio > canvasAspectRatio) {
      
      visual.h = this.DOM.canvas.height;
      if (visual.type == 'video') visual.w = visual.videoWidth * (visual.h / visual.videoHeight);
      if (visual.type == 'image') visual.w = visual.clientWidth * (visual.h / visual.clientHeight);
      
    } else {
      
      visual.w = this.DOM.canvas.height;
      visual.h = this.DOM.canvas.width;
      
    }

  }
  
  //**** Forms

  createRects(rectWidth) {

    this.DOM.rect1 = {
      width: rectWidth,
      height: rectWidth * 2,
      x: this.DOM.canvas.width * 0.5 - rectWidth,
      y: this.DOM.canvas.height * 0.5 - rectWidth
    };

    this.DOM.rect2 = {
      width: rectWidth * 2,
      height: rectWidth,
      x: this.DOM.canvas.width * 0.5 - rectWidth,
      y: this.DOM.canvas.height * 0.5 - rectWidth
    };

    this.DOM.rect3 = {
      width: rectWidth,
      height: rectWidth * 2,
      x: this.DOM.canvas.width * 0.5,
      y: this.DOM.canvas.height * 0.5 - rectWidth
    };

    this.DOM.rect4 = {
      width: rectWidth * 2,
      height: rectWidth,
      x: this.DOM.canvas.width * 0.5 - rectWidth,
      y: this.DOM.canvas.height * 0.5
    };

    this.DOM.rect5 = {
      width: rectWidth * 2,
      height: rectWidth * 2,
      x: this.DOM.canvas.width * 0.5 - rectWidth,
      y: this.DOM.canvas.height * 0.5 - rectWidth
    };

  }

  drawRects() {

    this.ctx.save();
    this.ctx.beginPath();
    if (!this.introStep2) {
      this.ctx.rect(this.DOM.rect1.x, this.DOM.rect1.y, this.DOM.rect1.width, this.DOM.rect1.height);
      this.ctx.rect(this.DOM.rect2.x, this.DOM.rect2.y, this.DOM.rect2.width, this.DOM.rect2.height);
      this.ctx.rect(this.DOM.rect3.x, this.DOM.rect3.y, this.DOM.rect3.width, this.DOM.rect3.height);
      this.ctx.rect(this.DOM.rect4.x, this.DOM.rect4.y, this.DOM.rect4.width, this.DOM.rect4.height);
    }
    if (this.introStep2) {
      if (!this.introEnded) this.ctx.rect(this.DOM.rect5.x, this.DOM.rect5.y, this.DOM.rect5.width, this.DOM.rect5.height);
      else this.ctx.rect(0, 0, this.DOM.canvas.width, this.DOM.canvas.height);
    }
    this.ctx.clip();

  }
  
  //**** Animation

  animate() {

    const IntroTimeline = new TimelineMax({ 
      paused: true,
      onStart: () => {
        this.DOM.Body.classList.add('is-animating');
        this.DOM.Body.classList.add('is-intro');
      },
      onComplete: () => {
        this.DOM.Body.classList.remove('is-intro');
      },
      delay: 1.2
    });


    //*** Text In
    IntroTimeline.staggerFrom(this.DOM.text.chars, 2, {
      opacity:0,
      y:20,
      x:-5, 
      ease:Power3.easeOut,
    }, 0.02, 0);


    //*** Mask Forms
    IntroTimeline.from([this.DOM.rect1, this.DOM.rect2], 2.4, {
      x: this.DOM.canvas.width * 0.2,
      y: this.DOM.canvas.height * 0.2,
      width: 0,
      height: 0,
      ease: Expo.easeInOut,
    }, 1);
    

    //
    IntroTimeline.from([this.DOM.rect3, this.DOM.rect4], 2.4, {
      x: this.DOM.canvas.width * 0.8,
      y: this.DOM.canvas.height * 0.8,
      width: 0,
      height: 0,
      ease: Expo.easeInOut,
    }, 1);
    
    
    //*** Text Out
    IntroTimeline.to(this.DOM.text, 0.8, {
      autoAlpha:0, 
      scale:0.8,
      ease:Expo.easeInOut,
    }, 1.8);


    //*** Mask FullHeight
    IntroTimeline.to(this.DOM.rect5, 2.4, {
      height: this.DOM.canvas.height,
      y: 0,
      ease: Expo.easeInOut
    }, 3.5);


    //*** Mask FullWidth
    IntroTimeline.to(this.DOM.rect5, 2.4, {
      width: this.DOM.canvas.width,
      x: 0,
      ease: Expo.easeInOut,
      onStart: () => {
        this.introStep2 = true;
      },
      onComplete: () => {
        this.introEnded = true;
      }
    }, 3.7);


    //*** Video Scale
    IntroTimeline.from(this.DOM.introVisual, 2.4, {
      w: this.DOM.introVisual.w * 1.4,
      h: this.DOM.introVisual.h * 1.4,
      ease: Expo.easeInOut,
    }, 3.9);
    
    
    IntroTimeline.from(document.querySelector('.Header > .container'), 2.4, {
      y: "-100%",
      ease: Expo.easeOut,
      clearProps:'transform'
    }, 5);
    
    IntroTimeline.from(this.DOM.view.querySelector('#about'), 2.4, {
      y: "50%",
      ease: Expo.easeOut,
      clearProps:'transform'
    }, 5);
    
    IntroTimeline.set(this.DOM.Body, {className:'-=is-animating'}, 5.8);
    
    IntroTimeline.from(this.DOM.view.querySelector('.Page__header .container'), 2.4, {
      y: "10%",
      opacity:0,
      ease: Expo.easeOut,
      clearProps:'transform'
    }, 5.8);
    
    IntroTimeline.from(this.DOM.view.querySelector('.Page__header .shape'), 2.4, {
      y: "10%",
      x: "5%",
      opacity:0,
      ease: Expo.easeOut,
      clearProps:'transform'
    }, 6);

    IntroTimeline.from(this.DOM.view.querySelector('.Page__header .filter-home'), 2.4, {
      opacity:0,
      ease: Expo.easeOut,
      clearProps:'opacity'
    }, 6);

    IntroTimeline.play();

  }
  
  //**** Canvas

  resizeCanvas() {

    const realToCSSPixels = window.devicePixelRatio || 1;
    const displayWidth = Math.floor(this.DOM.canvas.clientWidth * realToCSSPixels);
    const displayHeight = Math.floor(this.DOM.canvas.clientHeight * realToCSSPixels);

    if (this.DOM.canvas.width !== displayWidth || this.DOM.canvas.height !== displayHeight) {

      this.DOM.canvas.width = displayWidth;
      this.DOM.canvas.height = displayHeight;
      this.resizeVisual(this.DOM.introVisual);

    }

  }

  render() {

    this.ctx.clearRect(0, 0, this.DOM.canvas.width, this.DOM.canvas.height);
    this.resizeCanvas();
    this.drawRects();
    this.drawVideo();

  }

  destroy() {
    TweenMax.ticker.removeEventListener('tick', this.render, this, true, 1);
  }

}