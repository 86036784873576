// Highway
import Highway from '@dogstudio/highway';

export default class DefaultPageTransition extends Highway.Transition {

  in({ from, to, trigger, done }) {

    const Loader = document.querySelector('.Loader');

    // Remove Last Page
    //if (window.DEVMODE) console.log('Leaving Page : ', from);
    from.parentNode.removeChild(from);

    // Timeline animation page enter
    const pageIn = new TimelineMax({
      paused: true,
      onStart: () => {
        document.body.classList.remove('is-loading');
      },
      onComplete: () => {
        document.body.classList.remove('is-animating');
        done();
      }
    });

    pageIn.fromTo(Loader.querySelector('.inner'), 1, { y: 0 }, { y: '75%', ease: Power3.easeInOut, clearProps: 'all' }, 0);
    pageIn.fromTo(Loader.querySelector('.shape-load-1'), 1, { y: 0 }, { y: '120%', ease: Power3.easeInOut, clearProps: 'all' }, 0);
    pageIn.fromTo(Loader.querySelector('.shape-load-2'), 1, { y: 0 }, { y: '-60%', ease: Power3.easeInOut, clearProps: 'all' }, 0);
    pageIn.fromTo(Loader, 1, { y: 0 }, { y: '-100%', ease: Power3.easeInOut, clearProps: 'all' }, 0);
    pageIn.fromTo(to, 1, { opacity: 0, y: 100 }, { opacity: 1, y: 0, ease: Power3.easeInOut, clearProps: 'all' }, 0);
    pageIn.play();

  }

  out({ from, trigger, done }) {

    const Loader = document.querySelector('.Loader');

    // Timeline animation page leave
    const pageOut = new TimelineMax({
      paused: true,
      onStart: () => {
        document.body.classList.add('is-loading');
        document.body.classList.add('is-animating');
      },
      onComplete: () => {
        done();
      }
    });

    pageOut.fromTo(Loader.querySelector('.shape-load-1'), 1, { y: '-100%' }, { y: '0%', ease: Power3.easeInOut, clearProps: 'all' }, 0);
    pageOut.fromTo(Loader.querySelector('.shape-load-2'), 1, { y: '100%' }, { y: '0%', ease: Power3.easeInOut, clearProps: 'all' }, 0);
    pageOut.fromTo(Loader.querySelector('.shape-load-1'), 1, { y: '100%' }, { y: '0%', ease: Power3.easeInOut, clearProps: 'all' }, 0);
    pageOut.fromTo(Loader.querySelector('.inner'), 1, { y: '-75%' }, { y: '0%', ease: Power3.easeInOut }, 0);
    pageOut.fromTo(Loader, 1, { y: '100%' }, { y: '0%', ease: Power3.easeInOut }, 0);
    pageOut.to(from, 1, { opacity: 0, y: -20, ease: Power3.easeInOut, clearProps: 'all' }, 0);

    pageOut.play();

  }

}
