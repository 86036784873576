import Highway from '@dogstudio/highway';
import Intro from '../parts/intro.js';

export default class HomeRenderer extends Highway.Renderer {

	onEnter() {

		this.DOM = { view: this.wrap.lastElementChild };
		this.DOM.Intro = this.DOM.view.querySelector('#Intro');

		var mySwiper = new Swiper ('.swiper-news .swiper-container', {
			// Optional parameters
			loop: false,
			spaceBetween: 40,
			slidesOffsetAfter: 400,
			slidesPerView: 4,
			breakpoints: {
				330: {
				slidesPerView: 1,
				spaceBetween: 20,
				slidesOffsetAfter: 0
			},
				992: {
				slidesPerView: 2,
				spaceBetween: 20,
				slidesOffsetAfter: 0
			},
			  }

		});
		var mySwiper = new Swiper ('.swiper-studies .swiper-container', {
			// Optional parameters
			loop: false,
			spaceBetween: 40,
			slidesOffsetAfter: 400,
			slidesPerView: 4,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				330: {
				slidesPerView: 1,
				spaceBetween: 20,
				slidesOffsetAfter: 0
			},
				992: {
				slidesPerView: 2,
				spaceBetween: 20,
				slidesOffsetAfter: 0
			},
			  }

		});
		
		if (window.launchIntro) {
			this.Intro = new Intro(this.DOM.Intro, this.DOM.view);
			window.launchIntro = false;
		} else {
			this.DOM.Intro.classList.add('no--intro');
			if (this.DOM.Intro.querySelector('video')) this.DOM.Intro.querySelector('video').play();
		}

	}
	
	onLeaveCompleted() {
		
		if (window.launchIntro) this.Intro.destroy();
		
	}

}