import Highway from '@dogstudio/highway';

export default class SyndicatMixtePresentationRenderer extends Highway.Renderer {

	onEnter() {

		this.DOM = { view: this.wrap.lastElementChild };
		
		var mySwiper = new Swiper ('.swiper-team .swiper-container', {
			// Optional parameters
			loop: true,
			spaceBetween: 12,
			slidesOffsetAfter: 400,
			slidesPerView: 'auto',
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				330: {
				slidesPerView: 1,
				spaceBetween: 20,
				slidesOffsetAfter: 0
			},
				992: {
				slidesPerView: 2,
				spaceBetween: 20,
				slidesOffsetAfter: 0
			},
			  }

		});



		$('.open-popup-link').magnificPopup({
		  type:'inline',
		  closeBtnInside:true,
		  midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
		});


	}

}