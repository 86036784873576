import Highway from '@dogstudio/highway';

export default class SyndicatMixteTerritoireRenderer extends Highway.Renderer {

	onEnter() {

		this.DOM = { view: this.wrap.lastElementChild };
		
		$('.open-popup-link').magnificPopup({
		  type:'inline',
		  closeBtnInside:true,
		  midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
		});

	}

}