// Highway
import Highway from '@dogstudio/highway';
import DefaultPageRenderer from '../pages/page-renderer.js';
import DefaultPageTransition from '../pages/page-transition.js';
import HomeRenderer from '../pages/page-home.js';
import SyndicatMixtePresentationRenderer from '../pages/page-syndicat-mixte-presentation.js';
import SyndicatMixteTerritoireRenderer from '../pages/page-syndicat-mixte-territoire.js';
import CentreDeRessourcesRenderer from '../pages/page-centre-de-ressources.js';
import ActualitesRenderer from '../pages/page-actualites.js';
import ContactRenderer from '../pages/page-contact.js';
import SingleEtude from '../pages/single-etude.js';


import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageAnchors from './anchors.js';

export default class Routes {

  constructor(Header) {

    this.view = {};
    this.header = Header;
    this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');

    this.createHighway();
    this.initView(document.body);

    // add class header for items color
    document.querySelector("header").classList.add(document.querySelector("[data-header]").dataset.header);

  }


  createHighway() {

    const H = new Highway.Core({
      renderers: {
        pagename: DefaultPageRenderer,
        pageHome: HomeRenderer,
        pageSyndicatMixtePresentation: SyndicatMixtePresentationRenderer,
        pageSyndicatMixteTerritoire: SyndicatMixteTerritoireRenderer,
        pageCentreDeRessources: CentreDeRessourcesRenderer,
        pageActualites: ActualitesRenderer,
        pageContact: ContactRenderer,
        singleEtude: SingleEtude,
      },
      transitions: {
        default: DefaultPageTransition
      }
    });

    H.on('NAVIGATE_OUT', ({
      from,
      trigger,
      location
    }) => {

      if (window.DEVMODE) {

      }



      // RemovePrllx
      this.view.prllx.destroy();

    });

    H.on('NAVIGATE_IN', ({
      to,
      trigger,
      location
    }) => {

      if (window.DEVMODE) {

      }



      document.querySelector("header").classList.remove("black");
      document.querySelector("header").classList.remove("white");
      document.querySelector("header").classList.remove("full-white");
      document.querySelector("header").classList.remove("green");
      document.querySelector("header").classList.remove("white-black");
      document.querySelector("header").classList.add(to.view.dataset.header);



      // Menu active links
      this.navLinks.forEach((link) => {
        link.classList.remove('is-active');
        if (link.href === location.href) {
          link.classList.add('is-active');
        }
      });

      this.initView(to.view);

      function wait() {
        $('body').css('cursor', 'wait');
        $('button').css('cursor', 'wait');
        $('button').css('disabled', true);
        $('input').css('cursor', 'wait');
      }

      function wait_done() {
        $('body').css('cursor', 'default');
        $('button').css('cursor', 'default');
        $('button').css('disabled', false);
        $('input').css('cursor', 'default');
      }

      //FORM NEWSLETTER
      $("#newsletter_subscription").on("submit", function(e) {
        e.preventDefault();
        wait();
        var self = $(this);
        var ajaxurl = self.data("ajaxurl");
        var email = $("#input_newsletter").val();


        $.ajax({
          type: "POST",
          url: ajaxurl,
          data: self.serialize(),
          success: function(data) {
            wait_done();
            self.trigger('reset');
            var data = $.parseJSON(data);

            if (data.status == true) {
              $("#result_success").text(data.message).show();
              $("#result_error").hide();
            } else {
              $("#result_success").hide();
              $("#result_error").text(data.message).show();
            }
          },
          error: function(data) {
            wait_done();
            var data = $.parseJSON(data);

            $("#result_error").text(data.message).show();
          }
        });
      })

    });

    H.on('NAVIGATE_END', ({
      to,
      from,
      trigger,
      location
    }) => {

      if (window.DEVMODE) {}

      // Analytics
      /* if (typeof gtag !== 'undefined') {
      	 gtag('config', 'UA-XXXXXXXXX-X', {
      		 'page_path': location.pathname,
      		 'page_title': to.page.title,
      		 'page_location': location.href
      	 });
      } */

    });

  }

  initView(container) {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Menu Toggle
    if (this.header.menuOpen) {
      const click = new Event('click');
      this.header.DOM.toggle.dispatchEvent(click);
    }

    // Check Anchors
    if (container.querySelector('.anchors__nav')) {
      this.view.anchors = new pageAnchors(container.querySelector('.anchors__nav'));
    }

    // Prllx / InView
    this.view.prllx = new pagePrllx(container);
    this.view.inview = new pageInView(container);


  }

}
