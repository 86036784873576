import FastClick from 'modern-fastclick';

import { newSniff } from './modules/sniffer.js';
import Header from './parts/header.js';
import Footer from './parts/footer.js';
import Routes from './modules/routes.js';


window.DEVMODE = DEVMODE === 'development';
window.Sniff;
window.launchIntro = true;

class App {

  constructor() {

    // Declarations
    this.sy = 0;
    this.sniffer;
    this.DOM = {};
    this.DOM.Body = document.body;
    this.DOM.App = this.DOM.Body.querySelector('.App');
    this.DOM.Header = this.DOM.Body.querySelector('.Header');
    this.DOM.Footer = this.DOM.Body.querySelector('.Footer');
    this.DOM.Loader = this.DOM.Body.querySelector('.Loader');
    this.DOM.Outdated = this.DOM.Body.querySelector('#Outdated');
    this.DOM.Lines = this.DOM.Body.querySelector('#Lines');
    this.DOM.Intro = this.DOM.Body.querySelector('#Intro');

    // Signature Wokine
    console.log('%cCreated by Wokine', 'color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;');
    console.log("%chttps://wokine.com","color:#ccc");

    this.init();
    // this.addEvents();

  }

  init() {

    // DevMode
    if (window.DEVMODE) console.log('DEVMODE');
    

    // Sniffer
    window.Sniff = newSniff();


    // OutdatedBrowser
    if (window.Sniff.browser.name === 'ie' && window.Sniff.browser.majorVersion <= 10) {
      console.log('<= ie010');
      this.DOM.Body.classList.remove('is-first');
      this.DOM.Body.classList.remove('is-loading');
      this.DOM.Outdated.classList.add('show');
      return;
    }


    // Chrome Scroll Manual
    this.DOM.Body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    history.scrollRestoration = 'manual';


    // Create Header/Footer/Routes
    this.Header = new Header(this.DOM.Header);
    this.Footer = new Footer(this.DOM.Footer);
    this.Routes = new Routes(this.Header);
    //this.Lines = new Lines(this.DOM.Lines);


    // DOMContentLoaded
    document.addEventListener('DOMContentLoaded', () => {

      // Add Fast Click
      new FastClick(document.body);
      // Reset Scroll
      window.scrollTo(0, 0);
      
      console.log('DOMContentLoaded');
      
      setTimeout(()=> {
        document.body.classList.remove('is-first');
    		document.body.classList.remove('is-loading');
      }, 250);
      
      if (!document.querySelector('#Intro')) {
        document.body.classList.remove('is-intro');
        document.body.classList.remove('is-animating');
      }
  		
        
    }, false);

  }

  addEvents() {
    window.addEventListener('scroll', () => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
    });

  }


}

new App();
