import Highway from '@dogstudio/highway';

export default class ContactRenderer extends Highway.Renderer {

  onEnter() {

    this.DOM = { view: this.wrap.lastElementChild };

    function wait() {
      $('body').css('cursor', 'wait');
      $('button').css('cursor', 'wait');
      $('button').css('disabled', true);
      $('input').css('cursor', 'wait');
    }

    function wait_done() {
      $('body').css('cursor', 'default');
      $('button').css('cursor', 'default');
      $('button').css('disabled', false);
      $('input').css('cursor', 'default');
    }

    $("#form_contact").on("submit",function(e){
      e.preventDefault();
      $("#result").hide();
      wait();

      var self = $("#form_contact");
      var ajaxurl = self.data("ajaxurl");

      $.ajax({
        type: "POST",
        url: ajaxurl,
        data: self.serialize(),
        success: function(data) {
          wait_done();
          data = JSON.parse(data);
          $("#result").show().html(data.message);
          if (data.status == true) {
            self.trigger('reset');
          }
        },
        error: function(data) {
          wait_done();
        }
      });
    })


  }
}
